<template>
  <div id="welcome">
    <h3>Welcome to Permit Book! Let’s create your account</h3>
    <p class="instructions">
      Please enter a password in the fields below to get started.
    </p>
    <text-input
      class="password"
      label="Password"
      type="password"
      v-model="password"
    ></text-input>
    <text-input
      class="confirm-password"
      label="Confirm Password"
      type="password"
      v-model="confirmPassword"
    ></text-input>
    <p
      class="hint"
      :class="password.length > 0 && password.length < 6 ? 'show' : 'hide'"
    >
      Password must be at least 6 characters
    </p>
    <div class="button-row">
      <primary-button
        label="Pricing"
        v-bind:clicked="next"
        v-bind:disabled="formDisabled"
      ></primary-button>
    </div>
  </div>
</template>
<script>
import PrimaryButton from '../components/PrimaryButton'
import TextInput from '../components/TextInput'

export default {
  components: {
    PrimaryButton,
    TextInput
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      verificationCode: ''
    }
  },
  created() {
    this.verificationCode = this.$router.history.current.query.c

    if (!this.verificationCode) {
      this.$router.push('/')
    }
  },
  methods: {
    async next() {
      try {
        this.$events.$emit('showLoading')
        let result = await this.$userStore.verifyUser(
          this.verificationCode,
          this.password
        )
        await this.$session.logIn(result.emailAddress, this.password)
        this.password = ''
        this.confirmPassword = ''
        this.$router.push('/pricing')
      } catch (err) {
        let errMsg = 'Unknown Error'

        if (err.response && err.response.data && err.response.data.error) {
          errMsg = err.response.data.error
        } else if (err.message) {
          errMsg = err.message
        }

        this.$events.$emit('showBasicModal', {
          title: 'Error',
          text: errMsg,
          mode: 'ok',
          confirmText: 'OK'
        })
      } finally {
        this.$events.$emit('hideLoading')
      }
    }
  },
  computed: {
    formDisabled() {
      if (this.password.length < 6 || this.password !== this.confirmPassword) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
#welcome {
  position: relative;
  margin-top: -20px;

  h3 {
    width: 714px;
    height: 39px;
    margin-bottom: 32px;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #124e81;
  }

  p.instructions {
    margin-bottom: 34px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #505050;
    text-align: center;
  }

  .text-input {
    width: 378px;
    margin: 0 auto;
  }
  .text-input.password {
    margin-bottom: 35px;
  }

  .hint {
    width: 378px;
    margin: 31px auto 0;
    text-align: left;
    font-family: Barlow;
    font-size: 12px;
    opacity: 0;
  }
  .hint.show {
    opacity: 1;
  }

  .button-row {
    width: 100%;
    margin-top: 50px;
    text-align: center;
    .primary-button {
      width: 188px;
    }
  }
}
</style>
