<template>
  <div id="registration">
    <p class="main">
      The estimator tool below can help you estimate the cost of your monthly
      invoice. Your monthly bill will depend on the quantity of drivers you’d
      like to add.
    </p>
    <div class="slider-box">
      <h2>How many drivers do you have?</h2>
      <div class="info-row">
        <div class="info-item driver noselect">
          <div class="driver-title">{{ selectedPlanDrivers.range }}</div>
          <div class="driver-sub-title">
            driver{{ selectedPlanDrivers.plural ? 's' : '' }}
          </div>
        </div>
        <range-slider
          class="slider"
          min="0"
          :max="plan.options.length - 1"
          step="1"
          v-model="sliderValue"
        ></range-slider>
        <div class="info-item monthly-cost noselect">
          <div class="pricing-title">${{ selectedPlanPrice }}</div>
          <span class="pricing-sub-title">monthly cost</span>
        </div>
        <div style="clear: both"></div>
      </div>
      <br />
      <br />
    </div>
    <!--
    <p class="question">
      Need more than 40 drivers?
      <span class="contact-us" v-on:click="openContact()">Contact us</span> to talk about custom pricing
    </p>
    -->
    <div class="button-row">
      <primary-button
        label="Go To Dashboard"
        :clicked="openDashboard"
      ></primary-button>
    </div>
  </div>
</template>
<script>
import PrimaryButton from '../components/PrimaryButton'
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  components: {
    PrimaryButton,
    RangeSlider
  },
  data() {
    return {
      sliderValue: 0,
      plan: {
        options: []
      }
    }
  },
  created() {
    if (!this.$session.currentUser.id) {
      window.location.href = '/login'
    }
    this.setUp()
  },
  methods: {
    async setUp() {
      try {
        this.$events.$emit('showLoading')
        this.plan = await this.$subscriptionStore.getCurrentPlanOffering()
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    openContact() {
      this.$events.$emit('showSupportModal')
    },
    async openDashboard() {
      if (this.sliderValue === 0) {
        this.$session.setDefaultPage()
      } else {
        try {
          this.$events.$emit('showLoading')
          await this.$session.loadData()
          let data = this.$session.getUserData()

          if (data.isSuperAdmin) {
            this.$session.setDefaultPage()
            return
          }

          // Find out which company this user is an org admin for
          let companyMatch
          for (let i = 0; i < data.myCompanies.length; i++) {
            if (data.myCompanies[i].role === 'org_admin') {
              companyMatch = data.myCompanies[i]
              break
            }
          }

          if (!companyMatch) {
            this.$session.setDefaultPage()
            return
          }

          this.$events.$emit('hideLoading')

          let selectedTierPrice = this.plan.options[this.sliderValue].price
          this.sliderValue = 0

          this.$events.$emit('showPaymentModal', {
            mode: 'required',
            companyId: companyMatch.id,
            selectedTierPrice: selectedTierPrice,
            callback: () => {
              this.$session.updateCurrentCompany(companyMatch.id, true)
            }
          })
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }
    }
  },
  computed: {
    selectedPlanDrivers() {
      if (this.sliderValue >= this.plan.options.length) {
        return ''
      } else {
        let option = this.plan.options[this.sliderValue]
        let to = '-' + option.to
        let plural = option.to > 1

        // Handle the 500+ tier
        if (!option.to) {
          to = ''
          plural = true
        }
        if (option.to === option.from) {
          to = ''
        }

        return {
          range: option.from + to,
          plural: plural
        }
      }
    },
    selectedPlanPrice() {
      if (this.sliderValue >= this.plan.options.length) {
        return ''
      } else {
        let option = this.plan.options[this.sliderValue]
        return this.getFormattedCurrency(option.price)
      }
    },
    getFormattedCurrency: function () {
      return function (amount) {
        amount = parseFloat(amount / 100).toFixed(2)
        if (amount % 1 === 0) {
          amount = parseInt(amount)
        }
        return amount
      }
    }
  }
}
</script>

<style lang="scss">
#registration {
  position: relative;
  padding-top: 8px;

  p.main {
    width: 562px;
    text-align: center;
    font-family: Barlow;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #505050;
  }

  p.question {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: Barlow;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #505050;
    text-align: center;

    .contact-us {
      color: #124e81;
      cursor: pointer;
    }
  }

  .slider-box {
    width: 378px;
    height: 487px;
    margin: 32px auto 0;
    padding: 48px 37px 43px 38px;
    border-radius: 15px;
    box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
    border: solid 1px rgba(80, 80, 80, 0.2);
    background-color: #ffffff;
    text-align: center;

    h2 {
      margin-bottom: 28px;
      padding: 0 20px;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #124e81;
    }

    .slider {
      margin: 41px 0 34px;
      width: 256px;

      .range-slider-rail,
      .range-slider-fill {
        height: 10px;
        border-radius: 5.5px;
        background-color: #f4f8fe;
      }
      .range-slider-knob {
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-image: url(../assets/knob.png);
        background-size: 32px 32px;
      }
    }

    .info-row {
      .info-item {
        .driver-title,
        .pricing-title {
          font-family: Poppins;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #124e81;
        }
        .driver-sub-title,
        .pricing-sub-title {
          font-family: Barlow;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #505050;
        }
      }

      .info-item.driver {
        .driver-title {
          display: inline-block;
          font-size: 49px;
        }
        .driver-sub-title {
          display: inline-block;
          margin-left: 8px;
          font-size: 21px;
          vertical-align: 1px;
        }
      }

      .info-item.monthly-cost {
        .pricing-title {
          height: 89px;
          font-size: 66px;
        }
        .pricing-sub-title {
          font-size: 16px;
        }
      }
    }
  }

  .button-row {
    width: 100%;
    margin-top: 32px;
    text-align: center;

    .primary-button {
      width: 188px;
      height: 51px;
    }
  }
}
</style>
